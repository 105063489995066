<script setup>
import { ref, computed, watch, onUnmounted } from "vue";
import useKeyboard from "@/common/modules/keyboard/compKeyboard.ts";

const emit = defineEmits(["press", "longpress", "unpress"]);

const props = defineProps({
  keyChar: { type: String },
  beforeUnpress: { type: Function, default: () => true },
  pointerDown: {
    default:
      "ontouchstart" in window
        ? "touchstart"
        : "onpointerdown" in window
        ? "pointerdown"
        : "mousedown",
  },
  pointerOut: {
    default:
      "ontouchcancel" in window
        ? "ontouchcancel"
        : "onpointerout" in window
        ? "pointerout"
        : "mouseout",
  },
  pointerUp: {
    default:
      "ontouchend" in window
        ? "touchend"
        : "onpointerup" in window
        ? "pointerup"
        : "mouseup",
  },

  repeatOnLongpress: {
    type: Boolean,
    default: false,
  },

  disabled: {
    type: Boolean,
    default: false,
  },
});

const kb = useKeyboard();
const { keydown, keyup, keylongpress } = kb;

const cssClass = ref("");
let unpressDelayTimer = null;
let repeatTimer = null;

const keyState = computed(() => kb.keys.get(props.keyChar));

watch(keyState, (v, old) => {
  if (!props.disabled) {
    if (unpressDelayTimer) {
      clearTimeout(unpressDelayTimer);
      unpressDelayTimer = null;
    }
    if (v) {
      switch (v) {
        case "pressed":
          emit("press");
          break;
        case "longpressed":
          emit("longpress");
          longpress();
          break;
      }
      cssClass.value = v;
    } else {
      if (props.beforeUnpress(null, old)) {
        emit("unpress", old);
        unpress(old);
      }
      unpressDelayTimer = setTimeout(() => {
        cssClass.value = v;
      }, 100);
    }
  }
});

onUnmounted(() => {
  if (unpressDelayTimer) {
    clearTimeout(unpressDelayTimer);
    unpressDelayTimer = null;
  }

  if (repeatTimer) {
    clearInterval(repeatTimer);
    repeatTimer = null;
  }
});

function sendDown(evt) {
  evt.preventDefault();
  keydown(props.keyChar);
  //console.log(evt);
}

function sendUp(evt) {
  keyup(props.keyChar);
}

function contextMenu(evt) {
  evt.preventDefault();
  keylongpress(props.keyChar);
}

function longpress(state) {
  if (props.repeatOnLongpress) {
    emit("press");
    repeatTimer = setInterval(() => {
      emit("press");
    }, 250);
  }
}

function unpress(state) {
  if (repeatTimer) {
    clearInterval(repeatTimer);
    repeatTimer = null;
  }
}
</script>

<template>
  <div
    :class="cssClass"
    @[pointerDown]="sendDown"
    @[pointerOut]="sendUp"
    @[pointerUp]="sendUp"
    @contextmenu="contextMenu"
    @mousedown="sendDown"
    @mouseout="sendUp"
    @mouseup="sendUp"
  >
    <slot />
  </div>
</template>

<style lang="css">

.vkbd-button.pressed {
  background-color: rgba(128, 128, 128, 1);
}

.vkbd-button.longpressed {
  background-color: rgba(0, 0, 0, 1);
}
</style>
