import { name } from '../../../package.json';

let config = {}

async function loadCfg(cfgName: string){ 
  const resp = await fetch(cfgName);
  if(resp.ok){
    try {
      Object.assign(config, await resp.json());
    } catch {
      throw new Error(`Invalid ${cfgName}`);
    }
  } else {
    throw new Error(`Can\'t find ${cfgName}`)
  }
  console.log(`loaded ${cfgName}`)
}

async function init(){
  try {
    await loadCfg(`/${name}-cfg.json`);
  } catch ( e: any ) {
    console.warn(e.message)
    try {
      await loadCfg(`${name}-cfg.json`);
    } catch( e: any ) {
      alert( e.message );
    }
  }

}

await init();

export default config;