import { markRaw } from 'vue';
import { createI18n } from 'vue-i18n';

import messages from '@intlify/vite-plugin-vue-i18n/messages'

markRaw(messages);
//console.log(messages);

const i18n = createI18n({
  locale: import.meta.env.VITE_APP_I18N_LOCALE || 'ua', //'en',
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages,
  allowComposition: true,
  pluralizationRules: {
      ua: slavicPluralization
  }
})

/**
 * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
 * @param choicesLength {number} an overall amount of available choices
 * @returns a final choice index to select plural word by
**/

function slavicPluralization (choice, choicesLength, orgRule) {
  if (choice === 0) {
    return 0
  }

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1

  if (!teen && endsWithOne) {
    return 1
  }

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }

  return (choicesLength < 4) ? 2 : 3
}



export default i18n;