<script setup lang="ts" >
import { ref, computed, watch } from "vue";
import useKeyboard, {type TKeyState} from "@/common/modules/keyboard/compKeyboard";

const emit = defineEmits(["press", "longpress", "unpress"]);

const props = withDefaults(defineProps<{
  keyCodes: string;
  disabled?: boolean;
  beforeUnpress?: (pr: unknown, old:TKeyState) => boolean;
  cssClass?: string,
}>(), {
  disabled: false,
  beforeUnpress: () => true
});

const self = ref<HTMLDivElement|null>(null);
const codes = computed(() => props.keyCodes?.split(","));
const keyCode = computed(() => codes.value ? codes.value[0] : undefined);
const altCode = computed(() => codes.value ? codes.value[1] : undefined);

const kb = useKeyboard();
const { keydown, keyup, keylongpress } = kb;

const keyState = computed(() => {
  if(codes.value){
    for (const key of codes.value) {
      const state = kb.keys.get(key);
      if (state) {
        return state;
      }
    }
  }
  return undefined;
});

const extCss = computed(() => {
  return props.cssClass + " " + (props.disabled ? "inactive" : keyState.value || "");
});

watch(keyState, (v, old) => {
  if (!props.disabled && self.value?.offsetWidth ) {
    if (v) {
      switch (v) {
        case "pressed":
          emit("press");
          break;
        case "longpressed":
          emit("longpress");
          break;
      }
    } else {
      if (props.beforeUnpress(props, old!)) {
        emit("unpress", old);
      }
    }
  }
});

function sendDown(evt: Event) {
  evt.preventDefault();
  keyCode.value && keydown(keyCode.value);
}

function sendUp(evt: Event) {
  //evt.preventDefault();
  keyCode.value && keyup(keyCode.value);
}

function contextMenu(evt: MouseEvent) {
  evt.preventDefault();
  if ((evt as PointerEvent).pointerType == "mouse") {
    //prevent long touch contextMenu - fired AFTER dom update also
    keyCode.value && keylongpress(keyCode.value);
  }
}
</script>

<template>
  <div ref="self"
    class="side-menu-item"
    :class="extCss"
    @touchstart="sendDown"
    @touchcancel="sendUp"
    @touchend="sendUp"
    @pointerdown="sendDown"
    @pointerout="sendUp"
    @pointerup="sendUp"
    @mousedown="sendDown"
    @mouseout="sendUp"
    @mouseup="sendUp"
    @contextmenu="contextMenu"
  >
    <div class="altCode">{{ altCode }}</div>
    <slot />
  </div>
</template>

<style lang="css">

.side-menu-item.ok {
  background-image: url("../../assets/img/svg/ok.svg");
}

.side-menu-item.bin {
  background-image: url("../../assets/img/svg/bin.svg");
}

.side-menu-item.send {
  background-image: url("../../assets/img/svg/send.svg");
}

.side-menu-item.pressed {
  background-color: rgba(255, 255, 255, 0.7);
}

.side-menu-item.longpressed {
  background-color: rgba(255, 255, 255, 1);
}

/*
.side-menu-item.longpress:after {
		font-size: 6vh;
		text-shadow: 
			-0.25vh -0.25vh 0 white, 
			 0      -0.35vh 0 white, 
			 0.25vh -0.25vh 0 white, 
			 0.35vh  0      0 white, 
			-0.35vh  0      0 white, 
			-0.25vh  0.25vh 0 white, 
			 0       0.35vh 0 white, 
			 0.25vh  0.25vh 0 white;
		content: "..";
		position: relative;
		top: 12.5vh;
		left: 3.5vh;
}
*/


.side-menu-item {
  position: relative;
  overflow: hidden;
}

.side-menu-item > div.altCode {
  font-size: 1.5vh;
  vertical-align: top;
  padding: 0.5vmin;
  text-shadow: -2px -2px 0 #fff, 0 -2px 0 #fff, 2px -2px 0 #fff, 2px 0 0 #fff,
    -2px 2px 0 #fff, 0 2px 0 #fff, 2px 2px 0 #fff, 2px 0px 0 #fff;
}

.side-menu-item.longpress div::after {
  content: "radio_button_unchecked";
  font-size: 3vh;
  font-family: "Material Symbols";

  position: absolute;
  bottom: var(--app-control-margin);
  left: calc(var(--app-control-margin)*3.5);

  background-color: white;
  border-radius: 2vh;
}

.side-menu-item.inactive {
  opacity: 0.6;
}
</style>
