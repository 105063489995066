import { createWebHashHistory, createRouter } from 'vue-router'


const router = createRouter({
  //mode: 'history',
  base: import.meta.env.BASE_URL,
  history: createWebHashHistory(import.meta.env.BASE_URL+'map.html'),
  routes: [],
})

router.stack = [router.currentRoute.value];


router.pushOriginal = router.push;
router.replaceOriginal = router.replace;

router.push = async ( loc ) => {
  let route =  Object.assign({}, router.currentRoute.value);
  delete route.name;
  delete route.fullPath;
  delete route.href;
  if(typeof loc == 'string'){
    route.path = loc;
  } else {
    Object.assign(route, loc);
  }
	await router.pushOriginal( route );
  route = router.currentRoute.value;
  if( route.path == '/' ){
    router.stack.length = 0;
  } 
  router.stack.push(route);
}

router.replace = async ( loc ) => {
  let route =  Object.assign({}, router.currentRoute.value);
  delete route.name;
  delete route.fullPath;
  delete route.href;
  if(typeof loc == 'string'){
    route.path = loc;
  } else {
    Object.assign(route, loc);
  }
	await router.replaceOriginal( route );
  route = router.currentRoute.value;
  if( route.path == '/' ){
    router.stack.length = 1;
  } 
  router.stack[router.stack.length-1] = route;
}

router.normalizeUrl = (url) => {
	url = url || '/';
	const loc = Object.assign({}, router.currentRoute.value);
	if ( url[0] !== "/" ) {
	  if( loc.path.slice(-1)[0] !== "/") {
		  loc.path += "/";
	  }
	  loc.path += url;
	} else {
	  loc.path = url;
	}
  delete(loc.fullPath);
  delete(loc.href);
  delete(loc.name);
	return loc;
}

router.back = (n = 1) => {
	if(n > router.stack.length){
		n = router.stack.length;
	}
	if( n > 0){
		router.stack.length -= n;
	}
	let path;
	if( router.stack.length > 0 ){
		path = router.stack[router.stack.length-1].path;
	}
  if(! path ){
    path = '/';
  }

  const loc = router.normalizeUrl(path);
  if( path == '/'){
		router.stack = [loc]
	}
  return router.replaceOriginal( loc );
} 

router.updateQuery = ( items ) => {
  const route = Object.assign({}, router.currentRoute.value);
  route.query = Object.assign({}, route.query, items);
  return router.replace(route);
}

export default router;
