import {reactive} from "vue";
//import {computed, watch, watchEffect} from "vue";
import * as impKeycodes from "./keycodes";

type TTimeout = ReturnType<typeof setTimeout>;
type TNotifyEvent = 'keydown'|'keyup'|'keylongpress';
type TListnerArg = { name:TNotifyEvent, args: string[] }
type TListnerFunc = (arg:TListnerArg) => void;
type TListenerEntry = { listener: TListnerFunc|undefined }|undefined;
const keycodes = impKeycodes as {[key:string]: number};

export type TKeyState = 'pressed' | 'longpressed';

function useKeyboard() {

	const timers = new Map<string, TTimeout|undefined>();
	let listeners = new Set<TListenerEntry>();

	const keys =  reactive(new Map<string, TKeyState >());

	function $onAction( func: TListnerFunc ){
		let lsnr:TListenerEntry = { listener: func }
		listeners.add( lsnr );
		return function() {
			listeners.delete(lsnr);
			lsnr!.listener = undefined;
			lsnr = undefined;
		};
	}
	
	function notify(name:TNotifyEvent, key: string){
		const action = { name, args: [key] };
    for( const lsnr of listeners ){
      if(lsnr?.listener){
        new Promise(() => {
          lsnr.listener!( action );
        })
      }
    }
	}

	function startLongpressTimer(key: string){
		timers.set( key, setTimeout( () => {
			timers.set( key, undefined );
			keylongpress( key );
			timers.delete( key );
		}, 600 ));
	}

    function resetLongpressTimer(key: string){
		if( timers.has( key ) ){
			clearTimeout(timers.get( key )),
			timers.delete( key );
		}
	}

	function keydown( key: string ){
		if(! keys.has(key) ){
			startLongpressTimer(key);
			keys.set(key, 'pressed');
			notify('keydown', key);
		}
	};

	function keyup( key: string ){
		if( keys.has(key) ){
			resetLongpressTimer(key);
			keys.delete(key);
			notify('keyup', key);
		}
	};
	
	function keylongpress( key: string ){
		if( keys.get(key) != 'longpressed'){
			keys.set(key, 'longpressed');
			notify('keylongpress', key);
		}
	};
	
	function clear(){
		for( const pair of keys ){
			resetLongpressTimer(pair[0]/*keyName*/);
		}
		keys.clear();
	};

	return {
		get keys() { 
			return keys;
		},
		$onAction, keydown, keyup, keylongpress, clear,
	}
}

//
// attach keyboard to composable
//
const kb = useKeyboard();

function key_number( keyEvent: KeyboardEvent ){
	//return keyEvent.which >= 0x31 && keyEvent.which <= 0x38 && keyEvent.which - 0x31;
	const key = keyEvent.which;
	for( let idx in keycodes ){
		if(keycodes[idx] == key ){
			return idx;
		}
	}
	//console.log(keyEvent.key);
	return keyEvent.key;
}

//supress repeated keys by native keyboard longpress
const pressed = new Set<string>();

function onKeyDown(keyEvent: KeyboardEvent){
	const key = key_number(keyEvent);
	if(! pressed.has(key)){
		pressed.add(key);
		kb.keydown( key ); 
	}
};

function onKeyUp(keyEvent: KeyboardEvent){
	const key = key_number(keyEvent);
	pressed.delete(key);
	kb.keyup( key ); 
};

setTimeout( () => {
	window.document.addEventListener("keydown", (keyEvent) => onKeyDown(keyEvent) );
	window.document.addEventListener("keyup", (keyEvent) => onKeyUp(keyEvent) );
}, 2000 ); 

window.document.addEventListener("focusout", (event) => {
	for( const key of pressed ){
		kb.keyup( key );
	}
	pressed.clear();
	//console.log(event);
})
/*
{
	const f2 = computed(()=>{
		return  kb.keys.get('F2')
	});


	watch( kb.keys, (v) => {
		  console.log('watch keys:',JSON.stringify(Object.fromEntries(v)));
	});

	watch( f2, (v) => {
		console.log('watch f2:',JSON.stringify(v));
  	});

	watchEffect(() => {
		console.log('watchEffect keys:', JSON.stringify(Object.fromEntries(kb.keys)));
    },
	{
		onTrack: (info)=>{ 
			//console.log('watchEffect keys onTrack:', info);
		},
		onTrigger: (info)=>{ 
			//console.log('watchEffect keys onTrigger:', info);
		},
	});

	watchEffect(() => {
		//keys.__ob__.dep.depend();
		console.log('watchEffect f2:', JSON.stringify(f2.value));
    });

	console.log(Object.assign({},kb.keys.__ob__.dep.subs));
}
*/


export default function(){ 
	return kb
};