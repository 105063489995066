import di from '@/common/utils/di.js';


export default class HttpError extends Error{

	constructor(message, code, url){
		super( message );
		this.message = message;
		this.code = code;
		this.url = url;
	}
}
