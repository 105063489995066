
import HttpError from './HttpError.js';
import di from '@/common/utils/di.js';
import { goLogin } from '@/common/utils/urlUtils.js';
import useCredStore from '@/common/modules/credentials/store.js';
import cfg from '@/common/utils/runtimeConfig'

	
function authHeader(options){
	const opt = options || {};
	if( opt.noAuth ) return {};
	const credStore = useCredStore();
	const session_id = credStore.session_id;
	return session_id ? { 'Api-Session': session_id } : {};
};

async function processResponse(resp){
	if( resp.ok ){
		const json = await resp.json();
		if('debug' in json){
			console.info( json.debug );
		}
		if('result' in json ){
			return json.result;
		}else if( 'exception' in json ){
			const msg = json.exception?.msg || JSON.stringify(json.exception);
			throw new HttpError(msg, resp.status, resp.url);
		} else {
			throw new HttpError('Http response: missing result/exception fields', resp.status, resp.url);
		}
	} else {
		if( resp.status == 401 ){
			localStorage.removeItem('auth');
			goLogin();
		}
		let msg = resp.statusText;
		const contentType = resp.headers.get("content-type");
		if (contentType && contentType.indexOf("application/json") !== -1){
			const json = await resp.json();
			if( json['messages'] ){
				msg = json['messages'].join();
			}
			if( json.exception ){
				msg = json.exception.msg || JSON.stringify(json.exception);
			}
		} else {
			const text = await resp.text();
			if( text ){
				msg += '\n'+text;
			}
		}
		throw new HttpError(msg, resp.status, resp.url);
	}
}


async function getWithTimeout( url, timeout ){
	const host = url.startsWith('http') ? '' : cfg['api-root'];
	const headers = authHeader();
	const ac = new AbortController();
	const fetchPromise = fetch(host+url, { headers, signal: ac.signal });
	const timeoutId = setTimeout( () => ac.abort(), timeout );

	const resp = await fetchPromise;
	clearTimeout(timeoutId);

	return processResponse(resp);
};


async function get( url, options ){
	//await new Promise(r => setTimeout(r, 2000));

	const host = url.startsWith('http') ? '' : (options && options.host) || cfg['api-root'];
	const headers = authHeader(options);
	if( options && options.headers){
		for( let key in options.headers){
			headers[key] = options.headers[key];
		}
	}
	const resp = await fetch(host+url, { headers });
	
	if(options && options.rawResponse){
		return resp;
	}
	return processResponse(resp);
};


async function post( url, ent ){
	//await new Promise(r => setTimeout(r, 2000));
	
	const host = url.startsWith('http') ? '' : cfg['api-root'];
	const params = {
		method: "POST",
		headers: authHeader(),
	};
	if( ent ){
		const json = ent && ent.json ? ent.json() : JSON.stringify(ent);
		params.headers["Content-Type"] = "application/json";
		params.body = json;
	}
	const resp = await fetch( host+url, params );
	
	return processResponse(resp);
};


async function del( url, ent ){
	const host = url.startsWith('http') ? '' : cfg['api-root'];
	const headers = authHeader();
	headers["Content-Type"] = "application/json";
	const json = ent && ent.json ? ent.json() : JSON.stringify(ent);
	const resp = await fetch(
		host+url,
		{
			method: "DELETE",
			headers,
			body: json,
		}
	);
	return processResponse(resp);
};


export default {
	get, getWithTimeout,
	post,
	del
};