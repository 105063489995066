
export function	loadState( key:string, default_value?:any ){
	const json = localStorage.getItem(key);
	return json ? JSON.parse( json ) : default_value;
}
	
export function	saveState( key:string, val: any ){
	const json = JSON.stringify(val);
	if(json){
		localStorage.setItem(key, json);
	}
}
