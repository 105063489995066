import { ref, computed, toRef } from "vue";
import { defineStore } from 'pinia';

import di from "@/common/utils/di.js";

import { loadState, saveState } from '@/common/utils/localStorage.ts';

export default defineStore('credentials', () => {
	
	const site = toRef(di.config, 'unitName');
	const user = ref(null);
	const session_id = ref(null);
	const userParams = ref(null);

	const loggedin = computed( () =>  !! user );
	
	function $reset(){
		user.value = null;
		session_id.value = null;
		userParams.value = null;
	};

	function setCredentials( res ){
		user.value = res.user;
		session_id.value = res.session_id;
		userParams.value = res.params && JSON.parse(res.params);
		saveState( 'auth', res );
	};

	function restoreCredentials( ){
		const res = loadState('auth');
		if(res){
			user.value = res.user;
			session_id.value = res.session_id;
			userParams.value = res.params && JSON.parse(res.params);
		}
	};
	
	return { 
		site, user, userParams, session_id,
		loggedin, 
		$reset, restoreCredentials, setCredentials
	};
});

