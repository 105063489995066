import router from "./router.js";

import di from './di.js'

export function eraseCookieFromAllPaths(name) {
    // This function will attempt to remove a cookie from all paths.
    const pathBits = location.pathname.split('/');
    let pathCurrent = ' path=';

    // do a simple pathless delete first.
    document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;';

    for (let i = 0; i < pathBits.length; i++) {
        pathCurrent += ((pathCurrent.substr(-1) != '/') ? '/' : '') + pathBits[i];
        document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;' + pathCurrent + ';';
    }
}


export function deleteAllCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        eraseCookieFromAllPaths(name);
    }
}


export function getCookie(name) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  return parts.length == 2 ? 
	decodeURI(parts.pop().split(";").shift()) : 
	null;
}

export function getQueryParam( name, url ) {
    if (!url) url = location.href;
    name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    const regexS = "[\\?&]"+name+"=([^&#]*)";
    const regex = new RegExp( regexS );
    const results = regex.exec( url );
    return results == null ? null : decodeURI(results[1]);
}

export function back(level){
  const { router } = di;
  router.back(level);
}


export function go1(url, replace_history) {
  if(url.startsWith('http')){
	  location.href = url;
    return
	}
  const loc = router.normalizeUrl(url);
  if (router.currentRoute.value.path != loc.path) {
    if (replace_history) {
      return router.replace(loc);
    } else {
      return router.push(loc);
    }
  }
}

export function go(page){
	document.location.href = document.location.origin+'/'+page+'.html'
}

export function goLogin(){
	document.location.href ='/';
}

/***************************************************************************
function encode(v) {
  const arr = v.split("").map(ch => (ch == ":" ? 0 : parseInt(ch) + 1));
  let ret = "";
  let chunk = 0;
  let crc = 0;
  function addToChunk(c) {
    chunk <<= 5;
    const a = ((c ^ crc ^ 5) & 0xf) + 1;
    chunk += a;
//console.log("en:", c, crc, a, chunk);
    crc = c;
  }
  let i = 0;
  while (i < arr.length) {
    chunk = 0;
    n = 0
    while (chunk.toString(36).length < 6) {
      const rnd = Math.floor(Math.random() * 15 + 1);
      if (rnd > 10) {
        addToChunk(rnd);
      } else {
        if (chunk > 0 && i < arr.length) {
          addToChunk(arr[i++]);
        }
      }
    }
    ret += chunk.toString(36);
  }
  return ret;
}

function decode(v) {
  const chunks = v.match(/(.{6})/g);
  let crc = 0;
  let ret = "";
  if(chunks){
    for (const chunk of chunks) {
      let b = parseInt(chunk, 36);
      const bb = [];
      while (b > 0) {
        const c = b & 0x1f;
        bb.unshift(c);
        b >>= 5;
      }
      for (const b1 of bb) {
        const c = (((b1-1) ^ crc ^ 5 ) & 0xf);
//  console.log("dec:", b1, crc, c);
        if (c <= 10) {
          ret += c == 0 ? ":" : (c - 1).toString();
        }
        crc = c;
      }
    }
  }
  return ret;
  //return 190..toString(36);
}

const i1 = document.getElementById("i1");
const o1 = document.getElementById("o1");
const o2 = document.getElementById("o2");
const o3 = document.getElementById("o3");

function out(txt) {
  const v1 = encode(txt);
  o1.value = v1;
  o2.innerHTML = v1.length.toString();
  o3.innerHTML = decode(v1);
}

function change(evt) {
  out(i1.value);
}

function change1(evt) {
  o3.innerHTML = decode(o1.value);
}

out(i1.value);
 

<h1>Test</h1>
<input id="i1" value="1555682782:1234" oninput="change()"/>
<br>
<input id="o1" value="" oninput="change1()" size="80"/>
                        
<h2 id="o2">
</h2>

<h2 id="o3">
</h2>
 

*******************************************************/
