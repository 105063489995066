// Dependency injections container
export default {
	vue: {},
	router: {},
	store: {},
	config: { 
		locale: null,
		getUnitName: () => '',
		unitId: null,
		crs: '',
	}
};
